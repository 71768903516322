:root {
  --primary-color: #2405f2;
  --secondary-color: #3000ff;
  --light-blue: #e5f3ff;
  --bg-main: #eef0f4;
  --bg-secondary: #f1f1f1;
  --text-primary-color: #3b4758;
  --box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  --gradiant: linear-gradient(
    90deg,
    var(--primary-color),
    var(--secondary-color)
  );
  --gradiant-reverse: linear-gradient(
    90deg,
    var(--secondary-color),
    var(--primary-color)
  );
  --gradiant-red: linear-gradient(60deg, #d50000, #980404);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "regular";
  src: url("./assets/fonts/Roobert-Regular.woff");
  /* font-weight: normal;
  font-style: normal; */
}

@font-face {
  font-family: "semi-bold";
  src: url("./assets/fonts/Roobert-SemiBold.woff");
  /* font-weight: bold;
  font-style: bold; */
}
@font-face {
  font-family: "sanchez3";
  src: url("./assets/fonts/SanchezW03-XLt.woff");
  /* font-weight: bold;
  font-style: bold; */
}
@font-face {
  font-family: "sanchez5";
  src: url("./assets/fonts/SanchezW05-Regular.woff");
  /* font-weight: bold;
  font-style: bold; */
}

html {
  scrollbar-width: thin !important;
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #3b475834; /* Example color */
    border-radius: 4px;
  }
}

body {
  font-family: "regular", sans-serif;
  background-color: var(--bg-main);
  color: var(--text-primary-color);
  min-height: 100svh;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "semi-bold", sans-serif;
}

.custom-card {
  background-color: #fff;
  border-radius: 0.3em;
  box-shadow: 0 0 10px #0d0d0d0e;
  padding: 0.9em;
}

.btn-blue {
  background-color: var(--primary-color);
  color: #fff;
}

.btn-grey {
  background-color: var(--bg-secondary);
  color: var(--text-primary-color);
}

.btn-grey:hover {
  color: var(--bg-secondary);
  background-color: var(--text-primary-color);
}

.btn-gradiant {
  background: var(--gradiant);
  color: #fff;
}

.btn-gradiant:hover {
  background: var(--gradiant-reverse);
  color: #fff;
}

.full-canvas {
  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
